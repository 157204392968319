import React from 'react';
import { Box } from '@mui/material';
import DrawerNav from './DrawerNav';

const MainLayout = ({ children }) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                {children}
            </Box>
        </Box>
    );
};

export default MainLayout;