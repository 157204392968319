import React, { useEffect, useState } from 'react'
import MainLayout from '../Navigation/MainLayout'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Fab, TextField, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import axios from 'axios'
import { BASE_URL } from '../../Api/Constants'
import { formatDistanceToNow } from 'date-fns'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function QuizNotes() {
    const navigate = useNavigate()
    const { id } = useParams()
    const query = useQuery()
    const examName = query.get('name')

    const [notes, setNotes] = useState([])
    const [open, setOpen] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [currentNote, setCurrentNote] = useState(null)
    const [type, setType] = useState('text')
    const [value, setValue] = useState('')
    const [openDelete, setOpenDelete] = useState(false)

    const fetchNotes = (id) => {
        axios.get(`${BASE_URL}/api/admin/v2/quiz-notes/${id}`)
            .then(response => {
                setNotes(response.data.data.list)
            })
            .catch(error => {
                console.error('There was an error fetching the quiz notes!', error)
            })
    }

    useEffect(() => {
        fetchNotes(id)
    }, [id])

    const handleAddNote = () => {
        const url = editMode ? `${BASE_URL}/api/admin/v2/quiz-notes/${currentNote._id}` : `${BASE_URL}/api/admin/v2/quiz-notes`;
        const method = editMode ? 'put' : 'post';

        axios[method](url, { quizId: id, type, value })
            .then(response => {
                setOpen(false)
                setEditMode(false)
                setType('text')
                setValue('')
                fetchNotes(id)
            })
            .catch(error => {
                console.error(`There was an error ${editMode ? 'editing' : 'adding'} the quiz note!`, error)
            })
    }

    const handleEditNote = (note) => {
        setCurrentNote(note)
        setType(note.type)
        setValue(note.value)
        setEditMode(true)
        setOpen(true)
    }

    const handleDeleteNote = () => {
        axios.delete(`${BASE_URL}/api/admin/v2/quiz-notes/${currentNote._id}`)
            .then(response => {
                setOpenDelete(false)
                fetchNotes(id)
            })
            .catch(error => {
                console.error('There was an error deleting the quiz note!', error)
            })
    }

    const renderContent = (note) => {
        switch (note.type) {
            case 'image':
                return <img src={note.value} alt="note" style={{ maxWidth: '200px' }} className='rounded-2xl' />;
            case 'audio':
                return <audio controls src={note.value} />;
            case 'pdf':
                return <a href={note.value} target="_blank" rel="noopener noreferrer">View PDF</a>;
            default:
                return note.value;
        }
    }

    return (
        <MainLayout>
            <Typography variant="h4" gutterBottom>
                {examName} Quiz Notes
            </Typography>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Content</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notes.map(note => (
                            <TableRow key={note._id}>
                                <TableCell>{note.type && note.type.toUpperCase()}</TableCell>
                                <TableCell>{renderContent(note)}</TableCell>
                                <TableCell>{formatDistanceToNow(new Date(note.createdAt), { addSuffix: true })}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEditNote(note)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => { setCurrentNote(note); setOpenDelete(true); }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setOpen(true)}
                style={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}
            >
                <AddIcon />
            </Fab>

            <Dialog open={open} onClose={() => { setOpen(false); setEditMode(false); }}>
                <DialogTitle>{editMode ? 'Edit' : 'Add a New'} Note</DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        label="Note Type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        fullWidth
                        margin="dense"
                    >
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="image">Image</MenuItem>
                        <MenuItem value="pdf">PDF</MenuItem>
                        <MenuItem value="audio">Audio</MenuItem>
                    </TextField>
                    <TextField
                        label="Note Content"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        fullWidth
                        margin="dense"
                        multiline={type === 'text'}
                        rows={4}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false); setEditMode(false); }} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddNote} color="primary">
                        {editMode ? 'Update' : 'Add'} Note
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
                <DialogTitle>Delete Note</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this note?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDelete(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteNote} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </MainLayout>
    )
}

export default QuizNotes
