import React, { useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'
import { useNavigate } from 'react-router-dom'
import DrawerNav from '../Navigation/DrawerNav'

export default function AddCategory() {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [imageURL, setImageURL] = useState('')
    const navigate = useNavigate()

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/v1/category`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name,
                    description,
                    imageURL
                })
            })

            const data = await response.json()

            if (response.ok) {
                setName('')
                navigate(-1)
            } else {
                console.error(data.message)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add New Category
                    </Typography>
                    <TextField label='Category Name' value={name} onChange={(event) => setName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <TextField label='Category Description' value={description} onChange={(event) => setDescription(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <TextField label='Category Image' value={imageURL} onChange={(event) => setImageURL(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveChanges}>
                            Add Category
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
