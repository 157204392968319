import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
import { BASE_URL } from '../../Api/Constants';
import DrawerNav from '../Navigation/DrawerNav';

export default function ResourceDetails() {
    const { resource, id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const initialName = queryParams.get('name');
    const initialDescription = queryParams.get('description');
    const initialImageURL = queryParams.get('imageURL');

    const [name, setName] = useState(initialName || '');
    const [description, setDescription] = useState(initialDescription || '');
    const [imageURL, setImageURL] = useState(initialImageURL || '');

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/v1/${resource}/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    description,
                    imageURL,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                console.log('Resource updated successfully:', data.message);
                navigate(-1);
            } else {
                console.error('Error updating resource:', data.message);
            }
        } catch (error) {
            console.error('Error updating resource:', error);
        }
    };

    const handleDeleteResource = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/v1/${resource}/${id}`, {
                method: 'DELETE',
            });

            const data = await response.json();

            if (response.ok) {
                console.log('Resource deleted successfully:', data.message);
                navigate(-1);
            } else {
                console.error('Error deleting resource:', data.message);
            }
        } catch (error) {
            console.error('Error deleting resource:', error);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                    Edit {resource.charAt(0).toUpperCase() + resource.slice(1)}
                </Typography>
                <TextField
                    label='Name'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    fullWidth
                    variant='outlined'
                    margin='normal'
                />
                <TextField
                    label='Description'
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    fullWidth
                    variant='outlined'
                    margin='normal'
                />
                <TextField
                    label='Image URL'
                    value={imageURL}
                    onChange={(event) => setImageURL(event.target.value)}
                    fullWidth
                    variant='outlined'
                    margin='normal'
                />
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Button variant='contained' color='success' onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                    <Button variant='contained' color='error' onClick={handleDeleteResource}>
                        Delete {resource.charAt(0).toUpperCase() + resource.slice(1)}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
