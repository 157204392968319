// src/navItems.js

import { Home, Search, Logout, Image, Folder, Cases, Subject, Topic, Category, MusicNote } from '@mui/icons-material';
import { CardMedia } from '@mui/material';

export const navItems = [
    {
        value: 'Home',
        route: '',
        icon: <Home />
    },
    {
        value: 'Image Upload',
        route: 'image-upload',
        icon: <Image />
    },
    {
        value: 'Media Upload',
        route: 'media-upload',
        icon: <MusicNote />
    },
    {
        value: 'Search Question',
        route: 'search-question',
        icon: <Search />
    },
    {
        value: 'Add Category',
        route: 'add-category',
        icon: <Folder />
    },
    {
        value: 'View Categories',
        route: 'resources/category?parentName=',
        icon: <Topic />
    }
];

export const navItems2 = [
    {
        value: 'Category Management',
        route: 'management/category',
        icon: <Folder />
    },
    {
        value: 'Subject Management',
        route: 'management/subject',
        icon: <Subject />
    },
    {
        value: 'Quiz Management',
        route: 'management/quiz',
        icon: <Topic />
    },
];

export const logoutItem = {
    value: 'Logout',
    route: 'login',
    icon: <Logout />
};
